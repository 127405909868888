import { animate, group, query, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseDirective } from '@base-components/BaseDirective';
import { lastValueFrom } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { A2hsService } from './globalServices/a2hs.service';
import { ControllerService } from './globalServices/controller.service';
import { DatabaseService } from './globalServices/database.service';
import { DialogService } from './globalServices/dialog.service';
import { PWAUpdateService } from './globalServices/pwaUpdate.service';
import { GlobalSettingService } from './globalServices/settings/global-setting.service';
import { SyncService } from './globalServices/sync.service';
import { UserSessionService } from './globalServices/user-session-state.service';
import { ConnectionInfoCacheService } from './globalServices/webservice-connection-services/connectioninfo-cache.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('routeAnimation', [
      transition('1 => 2', [
        style({ height: '!' }),
        query(':enter', style({ transform: 'translateX(100%)' })),
        query(':enter, :leave', style({ position: 'absolute', top: 0, left: 8, right: 8 })),
        group([
          query(':leave', [animate('0.8s cubic-bezier(.35, 0 , .25, 1)', style({ transform: 'translateX(-100%)' }))]),
          query(':enter', [animate('0.8s cubic-bezier(.35, 0 , .25, 1)', style({ transform: 'translateX(0%)' }))]),
        ]),
      ]),
      transition('2 => 1', [
        style({ height: '!' }),
        query(':enter', style({ transform: 'translateX(-100%)' })),
        query(':enter, :leave', style({ position: 'absolute', top: 0, left: 8, right: 8 })),
        group([
          query(':leave', [animate('0.8s cubic-bezier(.35, 0 , .25, 1)', style({ transform: 'translateX(100%)' }))]),
          query(':enter', [animate('0.8s cubic-bezier(.35, 0 , .25, 1)', style({ transform: 'translateX(0%)' }))]),
        ]),
      ]),
    ]),
  ],
})
export class AppComponent extends BaseDirective implements OnInit, AfterViewInit {
  title = 'zeit:erfassung';

  // um ein refresh zu erzeugen, wenn es ein update gibt welches der service worker registriert
  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly dateAdapter: DateAdapter<Date>,
    private readonly pwaUpdateService: PWAUpdateService,
    private readonly dialog: DialogService,
    private readonly a2hsService: A2hsService,
    private readonly dbService: DatabaseService,
    private readonly controllerService: ControllerService,
    private readonly settings: GlobalSettingService,
    private readonly session: UserSessionService,
    private readonly syncService: SyncService,
    private readonly connectionInfoService: ConnectionInfoCacheService
  ) {
    super();
    this.dateAdapter.setLocale('de');

    void a2hsService.initEventHandlers().then(async () => {
      // Dieses Promise wird getriggert, wenn das A2HS-Event gespeichert wurde.
      // Es ist also eine Installation möglich und bspw. kann der Button dann angezeigt werden.
      const result = await this.dialog.QueryConfirmation(
        'zeit:erfassung Installation',
        'Möchten Sie die zeit:erfassung zum Homebildschirm hinzufügen?',
        'Hinzufügen',
        'Abbrechen'
      );
      if (result) {
        void a2hsService.triggerInstall();
      }
    });
  }

  /**@description Lauscht nach dem Wechsel der Screenausrichtung (sowieso nur unter IOS und nicht-standalone Modus möglich) */
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    const innerHeight = (event?.currentTarget as Window)?.innerHeight;
    const innerWidth = (event?.currentTarget as Window)?.innerWidth;
    if (innerWidth > innerHeight) {
      document.documentElement.style.setProperty('--vh', '1vh');
    } else {
      const newVh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${newVh}px`);
    }
  }

  @HostListener('window:popstate', ['$event'])
  async onPopState(): Promise<void> {
    await this.router.navigate(['']);
  }

  onSwipeBack(): void {
    const container = document.getElementById('container');

    container.addEventListener('touchstart', e => {
      const touchobj = e.changedTouches[0];
      if (touchobj.pageX > 10) return;

      e.preventDefault();
    });
  }

  async ngOnInit(): Promise<void> {
    await this.dbService.initDatabase();
    this.onSwipeBack();
    this.onResize(null);
    this.info();
    await this.checkTurnServer();
    await this.checkForFinishedUpdate();
    this.pwaUpdateService.checkForUpdates$.subscribe();
    this.onFocusSync();
    this.syncService.syncHandler$.subscribe();

    // Falls Nutzer eingelogt, direkt einloggen und gar nicht erst zum Login routen -
    // so kann ohne Verbindung reconnected werden ( wenn user bspw. browserrefresht ohne webserviceverbindung)

    await this.checkLoginRoute();
  }

  onFocusSync(): void {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    window.addEventListener('focus', async () => {
      const syncOnStart = await this.syncService.syncOnStart.get();
      const loggedIn = await this.session.loggedIn.get();
      if (syncOnStart && loggedIn) {
        this.syncService.triggerSync('onFocus');
      }
    });
  }

  async ngAfterViewInit(): Promise<void> {
    const loggedIn = await this.session.loggedIn.get();
    if (loggedIn) {
      const syncOnStart = await this.syncService.syncOnStart.get();
      if (syncOnStart) {
        this.syncService.triggerSync('init');
        return;
      }
    }
  }

  /**@todo Auslagern in Route-Guard */
  async checkLoginRoute(): Promise<void> {
    const params = await this.route.queryParamMap.pipe(first()).toPromise();
    const guid = params.get('allocationid');
    if (guid) {
      await this.router.navigate(['/login', { allocationid: guid }]);
    }
  }

  /**@description Infos über die runtime ausgeben */
  info(): void {
    console.log('Laufzeitumgebung ist: ' + environment.runtimeEnvironment);
    console.log('Configurationname ist: ' + environment.configurationname);
    console.log('Modus ist: ' + environment.production);
    console.log('Live-Status ist: ' + environment.live);
  }

  async checkForFinishedUpdate(): Promise<void> {
    const updateDone: boolean = await this.settings.updateDone.get();
    console.log('State of updateDone-Flag:', updateDone);
    if (!updateDone) {
      await this.dialog.ShowSpinnerWhile(
        this.pwaUpdateService.startUpdateProcess(),
        'Datenbank-Update',
        'Datenbank wird aktualisiert...'
      );
      await this.settings.updateDone.set(true);
    }
  }

  /**@description Prüft ob der TurnServer deprecated ist und aktualisiert die Allocation */
  async checkTurnServer(): Promise<void> {
    let turns = ['turn3.bssservices.de', 'turn4.bssservices.de'];
    console.log('Checking TurnServer...');
    const turnServer = await lastValueFrom(
      this.connectionInfoService.connectionInfo$.pipe(
        first(),
        map(info => info.allocation?.hostname)
      )
    );
    console.log('TurnServer:', turnServer);
    if (turns.includes(turnServer)) {
      console.log('Deprecated TurnServer was found. Refreshing Allocation...');
      await lastValueFrom(this.connectionInfoService.refreshAllocation());
    }
  }
}
